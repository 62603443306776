import { enableProdMode, LOCALE_ID, ErrorHandler, importProvidersFrom } from '@angular/core'
import localeNL from '@angular/common/locales/nl'
import { environment } from 'environments/environment'
import { AppComponent } from './app/app.component'
import { ToastrModule } from 'ngx-toastr'
import { provideAnimations } from '@angular/platform-browser/animations'
import { BrowserModule, bootstrapApplication } from '@angular/platform-browser'
import { HttpConfigInterceptor } from './app/_services/interceptor.service'
import { HTTP_INTERCEPTORS, withInterceptorsFromDi, provideHttpClient } from '@angular/common/http'
import { GoogleLoginProvider, SocialAuthServiceConfig, SocialLoginModule, GoogleSigninButtonModule } from '@abacritt/angularx-social-login'
import * as Sentry from '@sentry/angular-ivy'
import { registerLocaleData } from '@angular/common'
import { provideRouter } from '@angular/router'
import { ROUTES } from 'app/app.routes'

import {} from '@pqina/angular-pintura'

import { registerPlugin } from 'ngx-filepond'
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type'
import FilePondPluginFilePoster from 'filepond-plugin-file-poster'
import * as FilePondPluginImageEditor from '@pqina/filepond-plugin-image-editor'
import FilePondPluginImageValidateSize from 'filepond-plugin-image-validate-size'
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size'

import { defineLocale } from 'ngx-bootstrap/chronos'
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker'
import { nlLocale } from 'ngx-bootstrap/locale'
import { CURRENCY_MASK_CONFIG } from 'ng2-currency-mask'
import { ModalModule } from 'ngx-bootstrap/modal'

// the second parameter 'nl' is optional
registerLocaleData(localeNL, 'nl')

defineLocale('nl', nlLocale)

export function getDatepickerConfig(): BsDatepickerConfig {
    return Object.assign(new BsDatepickerConfig(), {
        dateInputFormat: 'DD/MM/YYYY',
        showWeekNumbers: false,
        locale: 'nl',
        selectFromOtherMonth: true,
    })
}

registerPlugin(
    (FilePondPluginImageEditor as { default: unknown }).default,
    FilePondPluginFilePoster,
    FilePondPluginImageValidateSize,
    FilePondPluginFileValidateType,
    FilePondPluginFileValidateSize
)

const host = window.location.host
const s = {
    project: environment.package.name,
    version: environment.package.version,
    env: environment.production ? 'production' : 'develop',
    location: host.endsWith('localhost:4200') ? 'local' : 'remote',
}
Sentry.init({
    dsn: environment.sentry.dsn[s.project],
    environment: s.env,
    ignoreErrors: ['Non-Error exception captured'],
})

if (environment.production) {
    enableProdMode()
}

bootstrapApplication(AppComponent, {
    providers: [
        provideRouter(ROUTES),
        importProvidersFrom(
            BrowserModule,
            ToastrModule.forRoot(),
            ModalModule.forRoot(),

            SocialLoginModule,
            GoogleSigninButtonModule
        ),
        { provide: BsDatepickerConfig, useFactory: getDatepickerConfig },
        {
            provide: CURRENCY_MASK_CONFIG,
            useValue: {
                align: 'right',
                allowNegative: true,
                decimal: '.',
                precision: 2,
                prefix: '',
                suffix: '',
                thousands: ' ',
            },
        },
        { provide: LOCALE_ID, useValue: 'nl-NL' },
        {
            provide: 'SocialAuthServiceConfig',
            useValue: {
                providers: [
                    {
                        id: GoogleLoginProvider.PROVIDER_ID,
                        provider: new GoogleLoginProvider('346758244346-tfsr2rlf7tl4vsbkj6f2n8mukdo2lgnv.apps.googleusercontent.com', {
                            oneTapEnabled: false,
                        }),
                    },
                ],
                onError: (err) => {
                    console.error(err)
                },
            } as SocialAuthServiceConfig,
        },
        {
            provide: ErrorHandler,
            useValue: Sentry.createErrorHandler({
                // showDialog: true,
            }),
        },
        { provide: HTTP_INTERCEPTORS, useClass: HttpConfigInterceptor, multi: true },
        provideHttpClient(withInterceptorsFromDi()),
        provideAnimations(),
    ],
}).catch((err) => console.log(err))
